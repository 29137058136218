export const themes = {
  dark: {
    global: {
      name: "dark",
      text: {
        dark: "#8696A7",
        blue: "#C7D1DB",
        link: "#579DFF",
        grey: "#d9d9d9",
        black: "#fff",
      },
    },
    nestedTabs: {
      tab: {
        background: "#1d2025",
        roundedBackground: "#1d2025",
      },
      preview: {
        background: "#DEEBFF",
      },
    },
    announcements: {
      actions: {
        bg: "#2C333A",
        color: "#a2acbb",
      },
      create: {
        bg: "#1d2024",
        icon: "#579DFF",
        border: "#579DFF",
      },
      design: {
        bg: "transparent",
        title: "#fff",
        text: "#eee",
        date: "#C7D1DB",
        border: "#A6C5E229",
      },
    },
    cards: {
      card: {
        bg: "#23272b",
        label: "#C7D1DB",
        text: "#8696A7",
        underlay: "#1d2125",
      },
      tabs: {
        active: {
          hover: "#0c2f62",
          color: "#579DFF",
          bg: "#082145",
        },
        inactive: {
          hover: "#323940",
          color: "#C7D1DB",
          bg: "#22272c",
        },
        create: {
          bg: "#1d2024",
          color: "#579DFF",
        },
      },
      actions: {
        bg: "#2C333A",
        color: "#a2acbb",
      },
    },
    configuration: {
      background: { inactive: "#1D2125", hover: "#2C333A" },
      icon: {
        cards: {
          background: "#003BFF",
          icon: "black",
        },
        easyHTML: {
          background: "#00D1C3",
          icon: "black",
        },
        pageRating: {
          background: "#00AFFF",
          icon: "black",
        },
      },
      border: "#A6C5E229",
      boxShadow: {
        hover:
          "0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 3px 5px 0px rgba(9, 30, 66, 0.20);",
        inactive:
          "0px 1px 1px 0px rgba(9, 30, 66, 0.25), 0px 0px 1px 0px rgba(9, 30, 66, 0.31)",
      },
    },

    shared: {
      modals: {
        promo: {
          title: "#C7D1DB",
          content: "#626F86",
        },
      },
      header: {
        bg: "linear-gradient(0deg, rgba(9, 30, 66, 0.14), rgba(9, 30, 66, 0.14)), linear-gradient(0deg, #121212 0%, #000 29%)",
        border: "#2f343a",
        color: "#C7D1DB",
        borderTopGradient:
          "linear-gradient(to right, #003661, #003BFF, #00AFFF, #00D1C3)",
        borderBottom: "#333B43",
        backgroundGradient: "linear-gradient(rgba(29, 33, 37, 1) 0 0)",
      },
      richTextEditor: {
        text: "#FFF",
      },
      form: {
        textarea: {
          background: "#343A43",
          divider: "#646464",
          actions: "#7a869a",
        },
      },
      content: {
        bg: "#171a1d",
      },
      emojis: {
        bg: "#23272b",
        border: "#A6C5E229",
        label: "#fff",
        active: "#579DFF",
      },
      input: {
        background: "#1D2125",
        border: "#A6C5E229",
        text: "#9FADBC",
        hover: "#A6C5E229",
      },
      tabs: {
        active: "#579DFF",
        inactive: "#9FADBC",
      },
      button: {
        delete: {
          bg: "#F87462",
          color: "#161A1D",
        },
        create: {
          bg: "#09326C",
          color: "#85B8FF",
        },
      },
      toolbar: {
        bg: "#1f2326",
        active: "#689bf8",
        inactive: "#99a0ae",
        icon: "#689bf8",
        text: "#C7D1DB",
        arrow: "#689bf8",
        border: "#1f2326",
      },
      sidebar: {
        background: "#23272b",
        section: "#C7D1DB",
        panel: "#1f2326",
        divider: "#A6C5E229",
        tabs: {
          text: { active: "#579DFF", inactive: "#9FADBC" },
          border: { active: "#579DFF", inactive: "#A6C5E229" },
        },
        imagePicker: {
          input: "#f7f6f4",
          upload: {
            bg: "#fafaf9",
            border: "#e6e6e5",
          },
        },
        switcher: {
          bg: { active: "#082145", inactive: "#A1BDD914" },
          color: { active: "#689bf8", inactive: "#c9d1da" },
          input: {
            border: "#A6C5E229",
            text: "#C7D1DB",
            bg: "#1D2125",
          },
        },
        sketch: {
          bg: "#1d2025",
          shadow: "#000000b3",
        },
        icon: {
          border: "#A6C5E229",
          color: "#a2acbb",
          bg: "#1D2125",
        },
      },
    },
  },
  light: {
    global: {
      name: "light",
      text: {
        dark: "#42526e",
        blue: "#172b4d",
        link: "#0052cc",
        grey: "#d9d9d9",
        black: "#000",
      },
    },
    nestedTabs: {
      tab: {
        background: "#ffffff",
        roundedBackground: "#f4f5f7",
      },
      preview: {
        background: "#DEEBFF",
      },
    },
    announcements: {
      create: {
        bg: "#f1f5ff",
        icon: "#0052cc",
        border: "#cddeff",
      },
      actions: {
        bg: "#f4f5f8",
        color: "#44546f",
      },
      design: {
        bg: "#fff",
        title: "#172b4d",
        text: "#455571",
        date: "#172b4d80",
        border: "#E5E5E5",
      },
    },
    cards: {
      card: {
        bg: "#fff",
        label: "#000",
        text: "#000",
        underlay: "#fff",
      },
      tabs: {
        active: {
          hover: "#ddeafd",
          color: "#0052cc",
          bg: "#ddeafd",
        },
        inactive: {
          hover: "#ebecf0",
          color: "#42526e",
          bg: "#f0f2f5",
        },
        create: {
          bg: "#fff",
          color: "#0065ff",
        },
      },
      actions: {
        bg: "#f4f5f8",
        color: "#44546f",
      },
    },
    configuration: {
      background: { inactive: "#FFFFFF", hover: "#F1F2F4" },
      icon: {
        cards: {
          background: "#003BFF",
          icon: "white",
        },
        easyHTML: {
          background: "#00D1C3",
          icon: "white",
        },
        pageRating: {
          background: "#00AFFF",
          icon: "white",
        },
      },
      border: "transparent",
      boxShadow: {
        hover:
          "0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 3px 5px 0px rgba(9, 30, 66, 0.20);",
        inactive:
          "0px 1px 1px 0px rgba(9, 30, 66, 0.25), 0px 0px 1px 0px rgba(9, 30, 66, 0.31)",
      },
    },
    shared: {
      modals: {
        promo: {
          title: "#172B4D",
          content: "#626F86",
        },
      },
      header: {
        bg: "#fff",
        border: "#e7e9f1",
        color: "#42526e",
        borderTopGradient:
          "linear-gradient(to right, #003661, #003BFF, #00AFFF, #00D1C3)",
        borderBottom: "#DDDFE4",
        backgroundGradient: "linear-gradient(rgb(255, 255, 255) 0 0)",
      },
      richTextEditor: {
        text: "#000",
      },
      form: {
        textarea: {
          background: "#f4f5f7",
          divider: "#DFE1E6",
          actions: "#42526e",
        },
      },
      content: {
        bg: "#f5f8fb",
      },
      emojis: {
        bg: "#fff",
        border: "#f2f3f7",
        label: "#42526e",
        active: "#07145a",
      },
      input: {
        background: "#fff",
        border: "#091e4224",
        text: "#172b4d",
        hover: "#f7f8f9",
      },
      tabs: {
        active: "#0052CC",
        inactive: "#07145a",
      },
      button: {
        delete: {
          bg: "#FF5630",
          color: "#fff",
        },
        create: {
          bg: "#DEEBFF",
          color: "#0065FF",
        },
      },
      toolbar: {
        bg: "#fff",
        active: "#07144e",
        inactive: "#b6b7bb",
        icon: "#0065FF",
        text: "#42526e",
        arrow: "#07145a",
        border: "#f2f3f7",
      },
      sidebar: {
        background: "#fff",
        section: "#172b4d",
        panel: "#f3f7ff",
        divider: "#A6C5E229",
        tabs: {
          text: { active: "#0052cc", inactive: "#42526e" },
          border: { active: "#0C66E4", inactive: "#dcdfe4" },
        },
        imagePicker: {
          input: "#f7f6f4",
          upload: {
            bg: "#fafaf9",
            border: "#e6e6e5",
          },
        },
        switcher: {
          bg: { active: "#DEEBFF", inactive: "#F4F5F7" },
          color: { active: "#0065FF", inactive: "#42526E" },
          input: {
            border: "#e5e7ef",
            text: "#42526E",
            bg: "#fff",
          },
        },
        sketch: {
          bg: "#fff",
          shadow: "#00000026",
        },
        icon: {
          border: "#f4f5f7",
          color: "#42526E",
          bg: "#f4f5f7",
        },
      },
    },
  },
};
