/* eslint-disable prefer-promise-reject-errors */
const ALL_JS_URL = "https://connect-cdn.atl-paas.net/all.js";

const contextIsIframe = () => {
  if (!window.self || !window.top) {
    return true;
  }
  return window.self !== window.top;
};

export const loadScript = ({ url, global }) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.onload = () => resolve(global && window[global]);
    script.onerror = () => resolve();
    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  });
};

export const loadScriptInHTMLHead = (scriptURL, onScriptLoaded) => {
  const scriptElement = document.createElement("script");
  scriptElement.onerror = (err) => {
    throw new Error(`Could not load script: ${err.target.src}`);
  };
  scriptElement.src = scriptURL;
  scriptElement.setAttribute("data-options", "sizeToParent:true;margin:false");
  scriptElement.onload = onScriptLoaded;

  document.head.appendChild(scriptElement);
};

export const initAppInConfluenceContext = () => {
  return new Promise((resolve, reject) => {
    if (!contextIsIframe()) {
      reject("not loading scripts since its not the expected iframe");
    }

    loadScriptInHTMLHead(ALL_JS_URL, () => resolve(true));
  });
};
