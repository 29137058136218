import React, { useEffect, useRef, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import ReactDOM from "react-dom";
import {
  createBrowserRouter,
  RouterProvider,
  matchRoutes,
} from "react-router-dom";

import load from "@loadable/component";
import { FlagsProvider } from "@atlaskit/flag";

import {
  initAppInConfluenceContext,
  loadScript,
} from "./confluence-app-initializer";

import { themes } from "./theme-colors";
import { ThemeContext } from "./theme-context";

const [
  MacrosuiteConfiguration,
  StyleguideLanding,
  StyleguideNews,
  StyleguideMacrosuite,
  StyleguidePermissions,
] = [
  load(() => import("./configuration/components/MacrosuiteConfiguration")),
  load(() => import("./styleguide/pages/Landing")),
  load(() => import("./styleguide/pages/News")),
  load(() => import("./styleguide/pages/Macrosuite")),
  load(() => import("./styleguide/pages/Permissions")),
];

const [
  TooltipFootnote,
  TooltipFootnoteEditor,
  FootnoteDisplay,
  FootnoteDisplayEditor,
  ImageSlider,
  ImageSliderEditor,
  Announcement,
  AnnouncementEditor,
  Designer,
  DesignerEditor,
  TabGroup,
  TabGroupEditor,
  Tab,
  TabEditor,
  Panel,
  PanelEditor,
  Optics,
  OpticsEditor,
  Button,
  ButtonEditor,
  EasyHTML,
  EasyHTMLEditor,
  Divider,
  DividerEditor,
  Section,
  SectionEditor,
  Cards,
  CardsEditor,
  Countdown,
] = [
  load(() => import("./features/tooltip-and-footnote/TooltipFootnote")),
  load(() => import("./features/tooltip-and-footnote/TooltipFootnoteEditor")),
  load(() => import("./features/footnote-display/FootnoteDisplayMacro")),
  load(() => import("./features/footnote-display/FootnoteDisplayMacroEditor")),
  load(() => import("./features/image-slider/Components/ImageSliderView")),
  load(() => import("./features/image-slider/Components/ImageSliderEditor")),
  load(() => import("./features/announcements/Components/View")),
  load(() => import("./features/announcements/Components/Editor")),
  load(() => import("./features/elementor/Components/ElementorView")),
  load(() => import("./features/elementor/Components/ElementorEditor")),
  load(() => import("./features/nested-tabs/tab-group/components/View")),
  load(() => import("./features/nested-tabs/tab-group/components/Editor")),
  load(() => import("./features/nested-tabs/tab/components/View")),
  load(() => import("./features/nested-tabs/tab/components/Editor")),
  load(() => import("./features/panel/PanelView")),
  load(() => import("./features/panel/PanelEditor")),
  load(() => import("./optics/components/view/View")),
  load(() => import("./optics/components/editor/Editor")),
  load(() => import("./features/button/ButtonMacro")),
  load(() => import("./features/Shared/Components/Editors/ButtonEditor")),
  load(() => import("./features/easy-html/plugin/EasyHtml")),
  load(() => import("./features/easy-html/plugin/EasyHtmlEditor")),
  load(() => import("./features/divider/DividerMacro")),
  load(() => import("./features/divider/DividerMacroEditor")),
  load(() => import("./features/section/Components/SectionView")),
  load(() => import("./features/section/Components/SectionEditor")),
  load(() => import("./features/cards-v2/CardsMacro")),
  load(() => import("./features/cards-v2/components/editor/Editor")),
  load(() => import("./features/countdown-v2/CountdownMacro")),
];

const getParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[[\]]/g, "\\$&");
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

const DEFAULT_COLOR_MODE = "light";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const css = (element, style) => {
  for (const property in style) {
    element.style[property] = style[property];
  }
};

const routes = {
  "tooltip-and-footnote": {
    View: <TooltipFootnote />,
    Edit: <TooltipFootnoteEditor />,
  },
  "footnote-display": {
    View: <FootnoteDisplay />,
    Edit: <FootnoteDisplayEditor />,
  },
  "title-and-text": { View: <Section />, Edit: <SectionEditor /> },
  "image-slider": { View: <ImageSlider />, Edit: <ImageSliderEditor /> },
  "easy-html": { View: <EasyHTML />, Edit: <EasyHTMLEditor /> },
  "cards-v2": { View: <Cards />, Edit: <CardsEditor editorType="macro" /> },
  "tab-group": { View: <TabGroup />, Edit: <TabGroupEditor /> },
  tab: { View: <Tab />, Edit: <TabEditor /> },
  panel: { View: <Panel />, Edit: <PanelEditor editorType="macro" /> },
  button: { View: <Button />, Edit: <ButtonEditor editorType="macro" /> },
  optics: { View: <Optics />, Edit: <OpticsEditor /> },
  divider: { View: <Divider />, Edit: <DividerEditor /> },
  designer: { View: <Designer />, Edit: <DesignerEditor /> },
  countdown: { View: <Countdown />, Edit: <Countdown isEditing /> },
  announcement: { View: <Announcement />, Edit: <AnnouncementEditor /> },
};

const router = createBrowserRouter([
  {
    path: "macrosuite-configuration/configuration",
    element: <MacrosuiteConfiguration />,
  },
  {
    path: "configuration/button-editor",
    element: (
      <QueryClientProvider client={queryClient}>
        <ButtonEditor editorType="configuration" />
      </QueryClientProvider>
    ),
  },
  {
    path: "configuration/card-editor",
    element: (
      <QueryClientProvider client={queryClient}>
        <CardsEditor editorType="configuration" />
      </QueryClientProvider>
    ),
  },
  {
    path: "configuration/panel-editor",
    element: (
      <QueryClientProvider client={queryClient}>
        <PanelEditor editorType="configuration" />
      </QueryClientProvider>
    ),
  },
  {
    path: "styleguide/permissions",
    element: <StyleguidePermissions />,
  },
  {
    path: "styleguide",
    element: <StyleguideLanding />,
  },
  {
    path: "styleguide/news",
    element: <StyleguideNews />,
  },
  {
    path: "styleguide/macrosuite",
    element: <StyleguideMacrosuite />,
  },
  {
    path: "macrosuite-info-banner",
    element: <QueryClientProvider client={queryClient}></QueryClientProvider>,
  },
  ...Object.keys(routes)
    .map((macro) => [
      {
        path: `macro/${macro}`,
        element: <>{routes[macro].View}</>,
      },
      {
        path: `macro/editor/${macro}`,
        element: (
          <QueryClientProvider client={queryClient}>
            {routes[macro].Edit}
          </QueryClientProvider>
        ),
      },
    ])
    .flat(),
]);

const checkIfRoutesMatch = () => {
  const availableRoutes = Object.keys(routes).map((macro) => [
    { path: `macro/${macro}` },
    { path: `macro/editor/${macro}` },
  ]);

  const { pathname } = window.location;
  const matches = matchRoutes(availableRoutes.flat(), { pathname });

  return matches?.length;
};

const individualMacroStyles = (macro, element) => {
  if (macro === "designer") {
    css(element, { height: "100vh" });
    css(document.body, {
      margin: "0 !important",
      background: "#fff",
    });
  } else {
    const style = document.createElement("style");

    style.textContent = `
      @media (max-width: 1740px) {
        html, body { height: 100%; }

        #content, .ac-content {
          max-width: 100% !important;
          height: 100% !important;
          margin: 0 !important;
        }
      }
    `;

    document.head.appendChild(style);

    css(element, {
      height: "90vh",
      maxWidth: "1700px",
      margin: "5vh auto",
      borderRadius: "20px",
      overflow: "hidden",
      background: "transparent",
    });
  }
};

const DARK_MODE_SUPPORTED = [
  "cards-v2",
  "button",
  "announcement",
  "panel",
  "divider",
  "image-slider",
  "tab-group",
  "tab",
  "title-and-text",
  "permissions",
  "macrosuite-configuration",
];

const App = () => {
  const [atlassianConnectLoaded, setAtlassianConnectLoaded] = useState(false);
  const [colorScheme, setColorScheme] = useState(DEFAULT_COLOR_MODE);

  const selectedMacro = useRef("");
  const rootElementRef = useRef(document.documentElement);
  const theme = themes[colorScheme];

  useEffect(() => {
    if (selectedMacro.current !== "designer") {
      window.AP?.theming?.initializeTheming();
    }
  }, [window?.AP]);

  useEffect(() => {
    if (
      DARK_MODE_SUPPORTED.find(
        (key) =>
          selectedMacro.current === key || window.location.href.includes(key)
      )
    ) {
      const rootElement = rootElementRef.current;
      const observer = new MutationObserver((mutationsList) => {
        mutationsList.forEach((mutation) => {
          if (mutation.attributeName === "data-color-mode") {
            const colorMode = rootElement.getAttribute("data-color-mode");
            setColorScheme(colorMode || DEFAULT_COLOR_MODE);
          }
        });
      });
      observer.observe(rootElement, { attributes: true });
      if (!rootElement.hasAttribute("data-color-mode")) {
        setColorScheme(DEFAULT_COLOR_MODE);
      }

      return () => observer.disconnect();
    }
  }, [selectedMacro.current]);

  useEffect(() => {
    (async () => {
      selectedMacro.current = getParameterByName("macro");

      if (["panel", "tab-group"].includes(selectedMacro.current)) {
        await loadScript({
          url: "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js",
          global: "jQuery",
        });
        await loadScript({
          url: "https://unpkg.com/@atlassian/aui@8.6.0/dist/aui/aui-css-deprecations.js",
        });
        await loadScript({
          url: "https://unpkg.com/@atlassian/aui@8.6.0/dist/aui/aui-prototyping.js",
        });
      }

      const isAtlassianScriptInitialized = await initAppInConfluenceContext();
      const context = await window.AP.context.getContext();
      const element = document.getElementsByClassName("ac-content");
      const invalidLicense = getParameterByName("invalid-license");
      const mode = getParameterByName("mode");

      if (invalidLicense) {
        element[0].classList.remove("hide");
        return setAtlassianConnectLoaded(isAtlassianScriptInitialized);
      }

      if (
        ["display", "preview"].includes(context?.confluence?.macro?.outputType)
      ) {
        element[0].classList.remove("hide");
      }

      if (mode === "custom-macro-edtior") {
        element[0].classList.remove("hide");
        if (element && element[0]) {
          individualMacroStyles(selectedMacro.current, element[0]);
        }
      }
      if (mode === "editor") {
        window.AP.dialog
          .create({
            key: "custom-macro-editor",
            width: "100%",
            height: "100%",
            customData: { macro: selectedMacro.current },
          })
          .on("close", () => {
            window.AP.confluence.closeMacroEditor();
          });
      } else {
        element[0].classList.remove("hide");
      }

      setAtlassianConnectLoaded(isAtlassianScriptInitialized);
    })();
  }, []);

  return atlassianConnectLoaded ? (
    <ThemeContext.Provider value={theme}>
      <FlagsProvider>
        <RouterProvider router={router} />
      </FlagsProvider>
    </ThemeContext.Provider>
  ) : (
    <div>Loading Optics</div>
  );
};

if (checkIfRoutesMatch()) {
  ReactDOM.render(<App />, document.getElementById("root"));
} else {
  // Load the legacy build
  const script = document.createElement("script");
  script.src = "/legacy/legacy.js";
  script.type = "text/javascript";
  document.head.appendChild(script);
}
